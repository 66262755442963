import * as React from "react";



import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";



import * as styles from "../styles/legal.module.scss";

export const Head = () => {
	return <HeadMeta title="Privacy Policy" />;
};
const PrivacyPolicyPage = () => {
	return (
		<Frame contained="true">
			<div className={styles.legalWrap}>
				<h1>Privacy Policy</h1>
				<div role="doc-subtitle">
					What does Cynosure do with your personal information?{" "}
				</div>
				<h2>Why?</h2>
				<p>
					Financial companies choose how they share your personal information.
					Federal law gives consumers the right to limit some but not all
					sharing. Federal law also requires us to tell you how we collect,
					share, and protect your personal information. Please read this notice
					carefully to understand what we do.
				</p>
				<h2>What? </h2>
				<p>
					The types of personal information we collect and share depend on the
					product or service you have with us. This information can include:
				</p>
				<ul role="none">
					<li>Social Security number and income</li>
					<li>Account balances and transaction history</li>
					<li>Assets and investment experience</li>
				</ul>
				<p>
					When you are no longer our customer, we continue to share your
					information as described in this notice.
				</p>
				<h2>How?</h2>
				<p>
					All financial companies need to share customers’ personal information
					to run their everyday business. In the section below, we list the
					reasons financial companies can share their customers’ personal
					information; the reasons Cynosure chooses to share; and whether you
					can limit this sharing.
				</p>
				<table>
					<thead>
						<tr>
							<th>Reasons we can share your personal information</th>
							<th>Does Cynosure share?</th>
							<th>Can you limit this sharing?</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<span className="mobileLabel">
									Reasons we can share your personal information
								</span>
								For our everyday business purposes—such as to process your
								transactions, maintain your account(s), respond to court orders
								and legal investigations
							</td>
							<td>
								<span className="mobileLabel">Does Cynosure share? </span>
								Yes
							</td>
							<td>
								<span className="mobileLabel">Can you limit this sharing?</span>
								No
							</td>
						</tr>
						<tr>
							<td>
								<span className="mobileLabel">
									Reasons we can share your personal information
								</span>
								For our marketing purposes—to offer our products and services to
								you
							</td>
							<td>
								<span className="mobileLabel">Does Cynosure share? </span>
								Yes
							</td>
							<td>
								<span className="mobileLabel">Can you limit this sharing?</span>
								No
							</td>
						</tr>
						<tr>
							<td>
								<span className="mobileLabel">
									Reasons we can share your personal information
								</span>
								For joint marketing with other financial companies
							</td>
							<td>
								<span className="mobileLabel">Does Cynosure share? </span>
								No
							</td>
							<td>
								<span className="mobileLabel">Can you limit this sharing?</span>
								We do not share
							</td>
						</tr>
						<tr>
							<td>
								<span className="mobileLabel">
									Reasons we can share your personal information
								</span>
								For our affiliates’ everyday business purposes— information
								about your transactions and experiences
							</td>
							<td>
								<span className="mobileLabel">Does Cynosure share? </span>
								Yes
							</td>
							<td>
								<span className="mobileLabel">Can you limit this sharing?</span>
								No
							</td>
						</tr>
						<tr>
							<td>
								<span className="mobileLabel">
									Reasons we can share your personal information
								</span>
								For affiliates to market to you
							</td>
							<td>
								<span className="mobileLabel">Does Cynosure share? </span>
								No
							</td>
							<td>
								<span className="mobileLabel">Can you limit this sharing?</span>
								We do not share
							</td>
						</tr>
						<tr>
							<td>
								<span className="mobileLabel">
									Reasons we can share your personal information
								</span>
								For nonaffiliates to market to you
							</td>
							<td>
								<span className="mobileLabel">Does Cynosure share? </span>
								No
							</td>
							<td>
								<span className="mobileLabel">Can you limit this sharing?</span>
								We do not share
							</td>
						</tr>
					</tbody>
				</table>
				<h2>Questions?</h2>
				<p>
					Call 801-521-3100 or go to{" "}
					<a href="https://cynosuregroup.com" target="_blank" rel="noreferrer">
						cynosuregroup.com
					</a>
				</p>
				<h2>Who we are</h2>
				<h3>Who is providing this notice?</h3>
				<p>Cynosure Management, LLC</p>
				<h2>What we do</h2>
				<h3>How does Cynosure protect my personal information?</h3>
				<p>
					To protect your personal information from unauthorized access and use,
					we use security measures that comply with federal law. These measures
					include computer safeguards and secured files and buildings.
				</p>
				<h3>How does Cynosure collect my personal information?</h3>
				<p>We collect your personal information, for example, when you</p>
				<ul role="none">
					<li>open an account or perform transactions</li>
					<li>make a wire transfer or tell us where to send money</li>
					<li>tell us about your investment portfolio</li>
				</ul>
				<p>We also collect your personal information from other companies.</p>
				<h3>Why can’t I limit all sharing?</h3>
				<p>Federal law gives you the right to limit only</p>
				<ul role="none">
					<li>
						sharing for affiliates’ everyday business purposes—information about
						your creditworthiness
					</li>
					<li>affiliates from using your information to market to you</li>
					<li>sharing for nonaffiliates to market to you</li>
				</ul>
				<p>
					State laws and individual companies may give you additional rights to
					limit sharing.
				</p>
				<h2>Definitions </h2>
				<h3>Affiliates</h3>
				<p>
					Companies related by common ownership or control. They can be
					financial and nonfinancial companies.
				</p>
				<h3>Nonaffiliates</h3>
				<p>
					Companies not related by common ownership or control. They can be
					financial and nonfinancial companies.
				</p>
				<ul role="none">
					<li>
						Cynosure does not share with nonaffiliates so they can market to
						you.
					</li>
				</ul>
				<h3>Joint Marketing</h3>
				<p>
					A formal agreement between nonaffiliated financial companies that
					together market financial products or services to you.
				</p>
				<ul role="none">
					<li>
						Cynosure does not share with nonaffiliates so they can market to
						you.
					</li>
				</ul>
				<time>Rev. April 2022</time>
			</div>
		</Frame>
	);
};

export default PrivacyPolicyPage;
